import React from 'react';
import { ThemeProvider as ThemeProviderSC } from 'styled-components';
import {
  colors,
  fontAndSpaces,
  mainColors,
  supportColors,
  baselineColors,
  systemColors,
  complementaryColors,
  ameColors,
  typography,
} from './theme-props';

const acomTheme = {
  ...colors,
  ...fontAndSpaces,
  mainColors,
  supportColors,
  baselineColors,
  systemColors,
  complementaryColors,
  ameColors,
  typography,
  fontFamily: 'Helvetica,Arial,sans-serif;',
  shadow: {
    floatBasket: '0px -8px 20px -6px rgba(0, 0, 0, 0.25);',
    box: '0 6px 20px -1px rgba(0,0,0,0.08);',
  },
  border: '1px solid #CCCCCC',
};

const ThemeProvider = props => <ThemeProviderSC theme={acomTheme} {...props} />;

export default ThemeProvider;
