import React from 'react';
import { withRouter } from 'react-router';
import { useSkin } from '@catalogo/service-americanas-skin';
import { ThemeProvider as ThemeProviderSC } from 'styled-components';
import {
  colors,
  fontAndSpaces,
  mainColors,
  supportColors,
  baselineColors,
  systemColors,
  complementaryColors,
  ameColors,
  typography,
} from '@catalogo/ui-americanas-mobile-theme-provider/src/theme-props';

export const acomTheme = {
  ...colors,
  ...fontAndSpaces,
  mainColors,
  supportColors,
  baselineColors,
  systemColors,
  complementaryColors,
  ameColors,
  typography,
  fontFamily: 'Helvetica,Arial,sans-serif;',
  shadow: {
    floatBasket: '0 0 12px 0 rgba(0,0,0,0.25)',
    box: '0 6px 20px -1px rgba(0,0,0,0.08);',
  },
  border: '1px solid #CCCCCC',
};

const ThemeProvider = props => {
  const skin = useSkin(props.history?.location);

  return <ThemeProviderSC theme={{ ...acomTheme, ...skin }} {...props} />;
};

export default withRouter(ThemeProvider);
