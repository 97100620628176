export const colors = {
  priceWishlistColor: '#333',
  buttonPrimaryColor: '#f80032',
  primaryColor: '#f80032',
  secondaryColor: '#ffeb3b',
  ameColor: '#FF006F',
  blue: '#24CBFF',
  yellow: '#FAD70A',
  green: '#66CE02',
  white: '#fff',
  black: '#000000',
  primaryColorTransparent: '#f8003214',
  primaryHoverColor: '#C60028',
  buttonLikeColor: '#369E26',
  buttonDislikeColor: '#f80032',
  backgroundGrey: '#f5f5f7',
  productNameColor: '#2d2d2d',
  success: {
    lighter: '#EFF8EA',
    light: '#DCEDC9',
    medium: '#40cd28',
    dark: '#00B100',
    darker: '#68CD00',
    nplDarker: '#008000',
  },
  error: {
    light: '#FFEBEE',
    medium: '#f80032',
  },
  warning: {
    light: '#fbeec1',
    medium: '#F2C832',
  },
  info: {
    lighter: '#E8FAFD',
    light: '#e1f5fe',
    medium: '#24cbff',
    dark: '#00B4D2',
    darker: '#147FE8',
  },
  grey: {
    lighter: '#f1f1f1',
    light: '#ccc',
    medium: '#999',
    dark: '#666',
    darker: '#333',
    font: '#9B9B9B',
  },
  red: {
    lighter: '#fcf5f5',
    light: '#ff3f4f',
    dark: '#ab0000',
  },
  card: {
    green: '#07AA07',
    badge: '#07AA07',
    ame: '#ED0059',
    cashback: '#AF2660',
  },
  textColor: '#666',
  titleColor: '#666',
};

export const fontAndSpaces = {
  fontSize: {
    xxxs: '10px',
    xxs: '12px',
    xs: '14px',
    sm: '16px',
    md: '18px',
    lg: '20px',
    xl: '24px',
    xxl: '30px',
  },
  fontWeight: {
    title: 'bold',
  },
  spacing: {
    xxs: '5px',
    xs: '10px',
    sm: '15px',
    md: '20px',
    lg: '35px',
    xl: '45px',
    xxl: '65px',
  },
  breakpoints: {
    xxs: '320px',
    xs: '480px',
    sm: '768px',
    md: '1025px',
    lg: '1280px',
    xl: '1360px',
  },
  titleFont: '18px',
  titleTransform: 'lowercase',
  btnTextTransform: 'lowercase',
  btnTextWeigth: '700',
  borderRadius: '8px',
  productCard: {
    textWeigth: '700',
    textTransform: 'lowercase',
  },
};

export const mainColors = {
  primary: {
    base: '#F80032',
    light10: '#F9325A',
    light20: '#FA6684',
    light30: '#FC99AD',
    light40: '#FCB2C1',
    light50: '#FDCCD6',
    dark10: '#C60028',
    dark20: '#94001E',
    dark30: '#7C0019',
    dark40: '#630014',
    dark50: '#4A000F',
  },
  secondary: {
    base: '#FAD70A',
    light10: '#FBDF3A',
    light20: '#FCE76C',
    light30: '#FDEF9D',
    light40: '#FDF3B5',
    light50: '#FEF7CE',
    dark10: '#C8AC08',
    dark20: '#968106',
    dark30: '#645604',
    dark40: '#4B4003',
    dark50: '#322B02',
  },
};

export const supportColors = {
  base: '#9E9E9E',
  light10: '#A9A9A9',
  light20: '#B4B4B4',
  light30: '#BEBEBE',
  light40: '#C9C9C9',
  light50: '#D4D4D4',
  light60: '#DFDFDF',
  light70: '#E9E9E9',
  light80: '#F4F4F4',
  light90: '#FAFAFA',
  dark10: '#868686',
  dark20: '#6F6F6F',
  dark30: '#575757',
  dark40: '#404040',
  dark50: '#282828',
};

export const baselineColors = {
  black: '#000000',
  white: '#FFFFFF',
};

export const systemColors = {
  success: {
    base: '#008000',
    light10: '#2B992B',
    light20: '#57B357',
    light30: '#82CC82',
    light40: '#AEE6AE',
    light50: '#D9FFD9',
    dark10: '#007200',
    dark20: '#006400',
    dark30: '#005600',
    dark40: '#004800',
    dark50: '#003A00',
  },
  alert: {
    base: '#F75B2B',
    light10: '#F9754E',
    light20: '#FA8F72',
    light30: '#FCAA95',
    light40: '#FDC4B9',
    light50: '#FFDEDC',
    dark10: '#D74C24',
    dark20: '#B83D1C',
    dark30: '#982E15',
    dark40: '#791F0D',
    dark50: '#591006',
  },
};

export const complementaryColors = {
  blue: {
    base: '#0071E0',
    light10: '#308DE6',
    light20: '#63AAEC',
    light30: '#94C6F3',
    light40: '#C7E5FC',
    light50: '#E5F9FF',
    dark10: '#0064C5',
    dark20: '#0058AB',
    dark30: '#004B90',
    dark40: '#003F76',
    dark50: '#00325B',
  },
  purple: {
    base: '#6721BF',
    light10: '#7E42CC',
    light20: '#9663D9',
    light30: '#AD83E5',
    light40: '#C5A4F2',
    light50: '#DCC5FF',
    dark10: '#5C1EAC',
    dark20: '#511B99',
    dark30: '#461786',
    dark40: '#3B1473',
    dark50: '#301160',
  },
  pink: {
    base: '#FF83BA',
    light10: '#FF97C6',
    light20: '#FFACD2',
    light30: '#FFC0DD',
    light40: '#FFD5E9',
    light50: '#FFE9F5',
    dark10: '#DF71A3',
    dark20: '#BF5F8C',
    dark30: '#A04D74',
    dark40: '#803B5D',
    dark50: '#602946',
  },
  turquoise: {
    base: '#4AB4B4',
    light10: '#5BC0BE',
    light20: '#6CCCC9',
    light30: '#7CD7D3',
    light40: '#8DE3DE',
    light50: '#9EEFE8',
    dark10: '#3CA4A3',
    dark20: '#2D9593',
    dark30: '#1F8582',
    dark40: '#107672',
    dark50: '#026661',
  },
  sapphire: {
    base: '#12698D',
    light10: '#2980A3',
    light20: '#4198B8',
    light30: '#58AFCE',
    light40: '#70C7E3',
    light50: '#87DEF9',
    dark10: '#0E5F7F',
    dark20: '#0B5571',
    dark30: '#074A63',
    dark40: '#044055',
    dark50: '#003647',
  },
};

export const ameColors = {
  cold: '#170085',
  cold10: '#112FA4',
  cold20: '#0C5EC2',
  cold30: '#068EE0',
  cold40: '#00BDFF',
  warm: '#ED0059',
  warmLight10: '#EF3867',
  warmLight20: '#F1546F',
  warmLight30: '#F27076',
  warmDark10: '#B61365',
  warmDark20: '#810D6F',
  warmDark30: '#4C067A',
};

export const typography = {
  family: `'Roboto', sans-serif`,
  fontFamily: { base: `'Roboto', sans-serif` },
  fontWeight: {
    thin: '100',
    light: '300',
    regular: '400',
    medium: '500',
    bold: '700',
    black: '900',
  },
  fontSize: {
    micro: '10px',
    tiny: '12px',
    mini: '14px',
    xxxs: '16px',
    xxs: '20px',
    xs: '24px',
    sm: '32px',
    md: '40px',
    lg: '48px',
    xl: '56px',
    xxl: '64px',
    xxxl: '72px',
    huge: '80px',
    giant: '96px',
  },
  lineHeight: {
    superTight: '100%',
    tight: '120%',
    medium: '140%',
    distant: '160%',
    superDistant: '200%',
  },
  letterSpacing: {
    none: '0%',
    tight: '4%',
    medium: '8%',
    distant: '16%',
    superDistant: '24%',
  },
};
